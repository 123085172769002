<template>
  <div>
    <div class="grid grid-cols-2 mb-4">
      <div class="col-span-1">
        <h2 class="text-2xl font-bold">Credit Applications</h2>
      </div>
      <div class="col-span-1">
        <div class="relative">
          <input
            type="search"
            name="search"
            class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
            placeholder="Search for employee’s name, status, etc."
            v-model="searchQuery"
          />
          <ion-icon
            name="search-outline"
            class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
            style="top: 50%; transform: translateY(-50%);"
          ></ion-icon>
        </div>
      </div>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :index="true"
        :reverse-index="true"
        :data="requests.data"
        :columns="requests.columns"
        :query="searchQuery"
        :loading="requests.loading"
        :onClick="selectRequest"
        ref="table"
      />
    </div>

    <modal className="w-full md:w-1/2 lg:w-8/10 xl:w-5/10" ref="modal">
      <h1 class="text-2xl font-bold mb-10">Review Credit Application</h1>

      <div v-if="request">
        <div class="mb-10">
          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                Registered Name
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon
                  name="business-outline"
                  class="text-lg mr-2"
                ></ion-icon>
                {{ request.company.name }}
              </div>
            </div>
            <div
              class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">Legal Name</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon
                  name="briefcase-outline"
                  class="text-lg mr-2"
                ></ion-icon>
                {{ request.business_name }}
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                Annual business revenue
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                {{ request.annual_revenue | currency }}
              </div>
            </div>
            <div
              class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                Total annual gross profit
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon
                  name="bar-chart-outline"
                  class="text-lg mr-2"
                ></ion-icon>
                {{ request.annual_gross_revenue | currency }}
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                Estimated monthly expenses
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                {{ request.est_monthly_expenses | currency }}
              </div>
            </div>
            <div
              class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                Number of years in business
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="medal-outline" class="text-lg mr-2"></ion-icon>
                {{ request.no_of_years }} years
              </div>
            </div>
          </div>
        </div>

        <div class="text-xl mb-5">Documents</div>

        <div class="mb-10">
          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                Two years bank statement
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <template
                  v-if="request.company.company_documents.bank_statements"
                >
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2 text-blue-500"
                  ></ion-icon>
                  <a
                    :href="request.company.company_documents.bank_statements"
                    download
                    class="text-blue-500"
                    target="_blank"
                    >View Document</a
                  >
                </template>
                <template v-else>
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  <span class="opacity-75">Not Uploaded</span>
                </template>
              </div>
            </div>
            <div
              class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                Board resolution
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <template
                  v-if="request.company.company_documents.resolution_docs"
                >
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2 text-blue-500"
                  ></ion-icon>
                  <a
                    :href="request.company.company_documents.resolution_docs"
                    download
                    class="text-blue-500"
                    target="_blank"
                    >View Document</a
                  >
                </template>
                <template v-else>
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  <span class="opacity-75">Not Uploaded</span>
                </template>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                CAC form Co2 - Share Allotment
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <template
                  v-if="request.company.company_documents.share_allotment_doc"
                >
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2 text-blue-500"
                  ></ion-icon>
                  <a
                    :href="
                      request.company.company_documents.share_allotment_doc
                    "
                    download
                    class="text-blue-500"
                    target="_blank"
                    >View Document</a
                  >
                </template>
                <template v-else>
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  <span class="opacity-75">Not Uploaded</span>
                </template>
              </div>
            </div>
            <div
              class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                CAC form Co7 - Particulars of directors
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <template
                  v-if="
                    request.company.company_documents.director_particulars_doc
                  "
                >
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2 text-blue-500"
                  ></ion-icon>
                  <a
                    :href="
                      request.company.company_documents.director_particulars_doc
                    "
                    download
                    class="text-blue-500"
                    target="_blank"
                    >View Document</a
                  >
                </template>
                <template v-else>
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  <span class="opacity-75">Not Uploaded</span>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="text-xl mb-5">Directors</div>

        <div class="mb-10">
          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                First Director's BVN
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon
                  name="briefcase-outline"
                  class="text-lg mr-2"
                ></ion-icon>
                {{ request.directors_bvn }}
              </div>
            </div>
            <div
              class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                First Director's National ID
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <template
                  v-if="request.company.company_documents.director_national_id"
                >
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2 text-blue-500"
                  ></ion-icon>
                  <a
                    :href="
                      request.company.company_documents.director_national_id
                    "
                    download
                    class="text-blue-500"
                    target="_blank"
                    >View Document</a
                  >
                </template>
                <template v-else>
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  <span class="opacity-75">Not Uploaded</span>
                </template>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                Second Director's BVN
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon
                  name="briefcase-outline"
                  class="text-lg mr-2"
                ></ion-icon>
                {{ request.directors_bvn_two || "N/A" }}
              </div>
            </div>
            <div
              class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                Second Director's National ID
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <template
                  v-if="
                    request.company.company_documents
                      .second_director_national_id
                  "
                >
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2 text-blue-500"
                  ></ion-icon>
                  <a
                    :href="
                      request.company.company_documents
                        .second_director_national_id
                    "
                    download
                    class="text-blue-500"
                    target="_blank"
                    >View Document</a
                  >
                </template>
                <template v-else>
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  <span class="opacity-75">Not Uploaded</span>
                </template>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">
                Third Director's BVN
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon
                  name="briefcase-outline"
                  class="text-lg mr-2"
                ></ion-icon>
                {{ request.directors_bvn_three || "N/A" }}
              </div>
            </div>
            <div
              class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200"
            >
              <div class="opacity-75 text-sm font-medium mb-1">
                Third Director's National ID
              </div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <template
                  v-if="
                    request.company.company_documents.third_director_national_id
                  "
                >
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2 text-blue-500"
                  ></ion-icon>
                  <a
                    :href="
                      request.company.company_documents
                        .third_director_national_id
                    "
                    download
                    class="text-blue-500"
                    target="_blank"
                    >View Document</a
                  >
                </template>
                <template v-else>
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  <span class="opacity-75">Not Uploaded</span>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-center flex-wrap">
          <button type="button" class="btn btn-md btn-red-outline mr-3">
            Decline Application
          </button>
          <button
            type="button"
            class="btn btn-md btn-blue"
            @click="comfirmApproval"
          >
            Grant Credit
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10"
      ref="approvalConfirmationModal"
    >
      <h1 class="text-2xl font-bold mb-10">Enter Credit Limit</h1>

      <form @submit.prevent="approve" v-if="request">
        <template v-if="getFormError(approveForm)">
          <div class="alert alert-red-soft mb-10">
            <div class="alert-icon">!</div>
            <span>{{ getFormError(approveForm) }}</span>
          </div>
        </template>

        <form-group
          type="money"
          left-icon="card-outline"
          name="credit_limit"
          :form="approveForm"
          v-model="approveForm.data.credit_limit.value"
        >
          Credit Limit
        </form-group>

        <form-group
          left-icon="card-outline"
          name="interest_rate"
          :form="approveForm"
          v-model="approveForm.data.interest_rate.value"
        >
          Interest Rate
        </form-group>

        <form-group
          left-icon="card-outline"
          name="billing_rate"
          :form="approveForm"
          v-model="approveForm.data.billing_date.value"
        >
          Billing Date
        </form-group>

        <div class="text-center">
          <button
            type="submit"
            class="btn btn-md btn-blue"
            :disabled="approveForm.loading"
          >
            <span v-if="approveForm.loading">Approving</span>
            <span v-else>Approve Credit</span>
          </button>
        </div>
      </form>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="approvalSuccessModal"
    >
      <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark" />

      <div class="text-lg font-bold mb-4">
        Credit Granted!
      </div>
      <div class="text-xs mb-10">
        Company's Credit Wallet Has Been Funded.
      </div>

      <button
        type="button"
        class="button bg-blue-500 hover:bg-blue-600 text-white"
        @click.prevent="$refs.approvalSuccessModal.close"
      >
        Okay
      </button>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="declineSuccessModal"
    >
      <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark" />

      <div class="text-lg font-bold mb-4">
        Credit Request Declined!
      </div>
      <!-- <div class="text-xs mb-10">
        The user
      </div> -->

      <button
        type="button"
        class="button bg-blue-500 hover:bg-blue-600 text-white"
        @click.prevent="$refs.declineSuccessModal.close"
      >
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      request: null,
      requests: this.$options.resource([], {
        columns: [
          {
            name: "company",
            th: "Company",
            render: (request) => request.company?.name,
          },
          {
            name: "business_name",
            th: "Legal Name",
            render: (request) => request.business_name,
          },
          {
            name: "annual_revenue",
            th: "Annual Revenue",
            render: (request) =>
              `₦ ${this.$options.filters.currency(request.annual_revenue)}`,
          },
          {
            name: "annual_gross_revenue",
            th: "Annual Gross Profit",
            render: (request) =>
              `₦ ${this.$options.filters.currency(
                request.annual_gross_revenue
              )}`,
          },
          {
            name: "status",
            th: "status",
            render: (request, status) => {
              switch (status) {
                case "approved":
                  return '<div class="badge badge-green-soft-outline">Approved</div>';
                case "declined":
                  return '<div class="badge badge-green-soft-outline">Declined</div>';
                case "pending":
                default:
                  return '<div class="badge badge-blue-soft-outline">Pending</div>';
              }
            },
          },
        ],
      }),
      approveForm: this.$options.basicForm([
        "credit_limit",
        "interest_rate",
        "billing_date",
      ]),
      checkmark: require("@/assets/checkmark-base.svg"),
      loading: null,
      searchQuery: "",
    };
  },
  computed: {},
  beforeMount() {
    this.getRequests();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    selectRequest(request) {
      this.request = request;
      this.$refs.modal.open();
    },
    comfirmApproval() {
      this.$refs.modal.close();
      this.$refs.approvalConfirmationModal.open();
    },
    comfirmDeclination() {
      this.$refs.modal.close();
      this.$refs.modal.open();
    },

    async getRequests() {
      this.requests.loading = true;
      await this.sendRequest("admin.companies.creditApplications.all", {
        success: (response) => {
          this.requests.data = response.data.card_requests;
        },
      });
      this.requests.loading = false;
    },
    async approve() {
      this.approveForm.loading = true;
      await this.sendRequest("admin.companies.creditApplications.approve", {
        data: {
          ...this.getFormData(this.approveForm),
          company_id: this.request.company_id,
        },
        success: () => {
          this.$refs.approvalConfirmationModal.close();
          this.$refs.approvalSuccessModal.open();
          this.getRequests();
          this.request = null;
        },
        error: (error) => {
          this.approveForm.error = error;
        },
      });
      this.approveForm.loading = false;
    },
    async decline() {
      this.approveForm.loading = true;
      await this.sendRequest("admin.cards.decline", {
        success: () => {
          this.getRequests();
        },
        error: (error) => {
          this.approveForm.error = error;
        },
      });
      this.approveForm.loading = false;
    },
  },
};
</script>